import React, { useEffect, useState } from 'react';
import './Menu.css';
import { Link } from 'react-router-dom';
import Product from '../../components/Product/Product';
import {fetchProductsByCategory,fetchCategories} from '../../api/api'

function Menu() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [products, setProducts] = useState({});
  const [category_list, setCategoryList] = useState([]);
  const topPosition = 120

  useEffect(() => {
    const getProductsByCategory = async () => {
        const data = await fetchProductsByCategory();
        setProducts(data);
    };

     const getCategory = async () => {
        const data = await fetchCategories();
        setCategoryList(data);
    };
    getProductsByCategory();
    getCategory();
    const category = window.location.hash || "";
    setSelectedCategory(category);

   if (category) {
      const targetElement = document.getElementById(category.substring(1)); // Remove the # and find the element
      if (targetElement) {
        const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - topPosition;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, []);

  const handleScroll = (cate) => {
    setSelectedCategory(cate)
    const id = `s-${cate}`
    const targetElement = document.getElementById(id);
    if (targetElement) {
          const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = window.innerWidth < 800 
            ? elementPosition - topPosition - 100 
            : elementPosition - topPosition;
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
  };

  return (
    <>
      {category_list.length > 0 && (
        <div className='my-container menu-container'>
          <nav id='menu-nav'>
            <ul>
              {category_list.map((cate, index) => (
                <li key={index} className={(cate.code === selectedCategory) ? "active" : ""}>
                  <Link to={`/menu#${"s-" + cate.code}`} onClick={() => handleScroll(cate.code)}>
                    {cate.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <div className='main-content'>
           {category_list.map((item, index) => (
              <section className='mb-5' id={`s-${item.code}`} key={index} aria-labelledby={`label-${item.code}`}>
                <h1 id={`label-${item.code}`} className='text-orange mb-4'>{item.name}</h1>
                <ul className='item-list'>
                  {Array.from(products[item.code] || []).map((product, index) => (
                    <Product product={product} key={index}></Product>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </div>
      )} 
    </>
  );
}

export default Menu;
