import Card from 'react-bootstrap/Card';
import img3 from '../../assets/review-1.png'
import img2 from '../../assets/review-2.png'
import img1 from '../../assets/review-3.png'
import banhmi from './../../assets/banhmi.png';
import "./Comments.css"
import { BsFillStarFill , BsQuote} from "react-icons/bs";
function Comments() {
  return (
    <section id='comment'>
        <img className='background-img' src={banhmi} alt="Vietnamese bread"></img>
        <h2 className='text-green mb-4'>From our beloved customers ...</h2>
    <ul id='comment-grid'>
      <li id='comment-1'>
        <Card style={{height: "100%",boxShadow: '5px 5px 10px rgb(0,0,0,0.1)'}}>
          <div className='content'>
              <img src={img1} alt='Tobemilk tea food and drink'/>
              <div>
                <p className='text-orange quote'><BsQuote /></p>
                <p className='text-yellow'><BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/></p>
                <p>I love this place! The food is delicious (my favorite is the banh mi), and the staff are very kind. They regularly switch out the decorations, and it's super cute! Everything is priced well, and I look forward to going there more :)) </p>
                <p className='text-italic'>- Kayci Roy -</p>
              </div>
          </div>
        </Card>
      </li>
        <li id='comment-2'>
        <Card style={{boxShadow: '5px 5px 10px rgb(0,0,0,0.1)'}}>
          <div className='content'>
             <img src={img2} width={100} alt='Tobemilk tea food and drink'/>
              <div>
                <p className='text-yellow'><BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/></p>
                <p>“Listen! When I tell you that the food is amazing, just believe me. So do yourself a favor and order anything from them. I was in Fredericton for 10 days and ate from them 3 times. You're going to need to walk after that meal because when I tell you I was full!! I ate the vermicelli noodles with tofu and their bubble tea was on point. Definitely check it out!”</p>
                <p className='text-italic'>- Kalisa Young -</p>
              </div>
          </div>
        </Card>
      </li>  
      <li id='comment-3'>
        <Card style={{boxShadow: '5px 5px 10px rgb(0,0,0,0.1)'}}>
          <div  className='content'>
             <img src={img3} width={100} alt='Tobemilk tea food and drink'/>
              <div>
                <p className='text-yellow'><BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/> <BsFillStarFill/></p>
                <p>“Surprisingly very delicious banh mi, generously proportioned and comparable (or even better) to ones found in bigger cities like Toronto. The bubble tea is also very good. A refreshing break from the monotony of pub fare in downtown Fredericton. Def coming back.”</p>
                <p className='text-italic'>- Kuhn-Mook Lee -</p>
              </div>
          </div>
        </Card>
      </li>
    </ul>
    </section>
  );
}

export default Comments;