import Card from 'react-bootstrap/Card';
const BASE_URL = process.env.REACT_APP_API_URL;

function VerCard({img,name,price}) {
  return (
    <li>
      <Card style={{ width: '10rem', boxShadow: '5px 5px 10px rgb(0,0,0,0.1)', height : "100%"}}>
        <Card.Img alt={name} class='product-img' variant="top" src={`${BASE_URL}/image/product/${img}`} style={{padding : '0.2rem', width:'100%'}}/>
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text>
            ${price}
          </Card.Text>
        </Card.Body>
      </Card>
    </li>
   
  );
}

export default VerCard;