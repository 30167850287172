import React from 'react';
import './Navbar.css';
import logo from "./../../assets/logo.png"
import { NavLink , Link} from 'react-router-dom';
import Button from './../../components/Button/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsJustify } from "react-icons/bs";
const Navbar = () => {
  return (
    <nav id='nav-containter'>
      <div className='mynavbar my-container'>
        <Link to='/'><img className='header-logo' src={logo} alt='Logo'></img></Link>
        <div className='flex-grow-1'>
        <div className='desktop-only'>
        <div className='desktop-nav'> 
         <ul className='navlinks'>
            <li><NavLink to='/'>Home</NavLink></li>
            <li><NavLink to='/menu'>Menu</NavLink></li>
            <li><NavLink to='/promotion'>Promotion</NavLink></li>
            <li><NavLink to='/contact'>Contact Us</NavLink></li>
          </ul>

          <Dropdown autoClose="outside">
          <Dropdown.Toggle id="order-button" variant='primary' className='mb-4'>Order Now</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="https://www.skipthedishes.com/tobe-milk-tea-betty-lees" target='_blank'>
              Skip The Dishes
            </Dropdown.Item>
            <Dropdown.Item href="https://www.ubereats.com/ca/store/betty-lees-baking-paradise-tobe-milk-tea/mh0tDH3hUO200j8dnhvg5w" target='_blank'>
              Uber Eats
            </Dropdown.Item>
            <Dropdown.Item href="https://www.doordash.com/en-CA/store/betty-lee's---baking-paradise---tobe-milk-tea-fredericton-23836195/" target='_blank'>
              DoorDash
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        
        </div>
         
      
        </div>
        <div className="mobile-only">
          <div className="mobile-nav">
          <Link role='button' to='/menu' className="btn btn-secondary">Menu</Link>
          <Dropdown className="mobile-only" autoClose="outside">
            <Dropdown.Toggle variant='primary' id="order-button">Order Now</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="https://www.skipthedishes.com/tobe-milk-tea-betty-lees" target='_blank'>
                Skip The Dishes
              </Dropdown.Item>
              <Dropdown.Item href="https://www.ubereats.com/ca/store/betty-lees-baking-paradise-tobe-milk-tea/mh0tDH3hUO200j8dnhvg5w" target='_blank'>
                Uber Eats
              </Dropdown.Item>
              <Dropdown.Item href="https://www.doordash.com/en-CA/store/betty-lee's---baking-paradise---tobe-milk-tea-fredericton-23836195/" target='_blank'>
                DoorDash
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown className="mobile-only" autoClose="outside">
            <Dropdown.Toggle variant='none' id="dropdown-button"><BsJustify/></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/">Home</Dropdown.Item>
              <Dropdown.Item href="/promotion">Promotion</Dropdown.Item>
              <Dropdown.Item href="/contact">Contact Us</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
        </div>
      </div>
      </div>
    </nav>
  )
}

export default Navbar;