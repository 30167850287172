import React, { useLayoutEffect } from 'react';
import { Routes, Route, BrowserRouter as Router, useLocation } from 'react-router-dom';
import Home from "./pages/Home/Home";
import Layout from "./partial/Layout";
import Menu from "./pages/Menu/Menu";
import Contact from "./pages/Contact/Contact";
import NotFound from "./pages/NotFound/NotFound";
import "./App.css"
import Promotion from './pages/Promotion/Promotion';
import bg from "./assets/Ellipse 1.svg"

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [location.pathname]);

  return children;
};

export default function App() {
  return (
    <Router>
      <div className='background'>
        <img src={bg} alt="background" className='img1'></img>
        <img src={bg} alt="background" className='img2'></img>
      </div>
     
      <Wrapper>
        <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route  path="menu" element={<Menu />} />
              <Route  path="contact" element={<Contact />} />
              <Route  path="promotion" element={<Promotion />} />
              <Route  path="*" element={<NotFound />} />
            </Route>
          </Routes>
      </Wrapper>
  </Router>
  );
}
