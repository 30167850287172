
import Navbar from "./Navbar/Navbar";
import { Outlet } from 'react-router-dom';
import Footer from "./Footer/Footer";

const Layout = () => {
  return (
    <>
      <header>
        <Navbar />
      </header>
      <main>
        <Outlet /> 
      </main>
      <Footer />
    </>    
  )
};

export default Layout;
