import Card from 'react-bootstrap/Card';
import "./Product.css"


function Product({product}) {
  const BASE_URL = process.env.REACT_APP_API_URL;
  return (
    <li>
      <Card className='product-card'>
        <div className='layout'>
          <Card.Img variant="top" class='product-img' src={`${BASE_URL}/image/product/${product.image}`} style={{padding : '0.2rem'}}/>
          <Card.Body>
            <Card.Title>{product.name}</Card.Title>
              {!product.discontinued? <>
                    {(product.discount_percentage)? 
                      <Card.Text className='price'>$<span className='old-price'>{product.price}</span> <span>{product.price*(100-product.discount_percentage)/100}</span> </Card.Text>
                      : 
                      <Card.Text className='price'>${product.price} </Card.Text>}   

                    <Card.Text>{product.description}</Card.Text>

                    {product.tags  && 
                      <ul>
                      {Array.from(product.tags.split(",")).map((item,index) => (
                        <li className='diet-tag' key={index}>
                          {item}
                        </li>
                      ))}
                      </ul>}
                      <ul className='promotion-tags'>
                        {(product.discount_percentage > 0)&& <li>{product.discount_percentage}% off</li>}
                        {(product.promotion) && <>
                          {(product.promotion.split(',')).map((item,index)=> (
                            <li key={index}>{item}</li>
                          ))}
                        </>}
                      </ul>
                  </>
                : <Card.Text>Discontinued</Card.Text>}
          </Card.Body>
        </div>
      </Card>
    </li>
   
  );
}

export default Product;