import React from 'react';
import './Contact.css';
import img1 from "./../../assets/contact-1.png"
import Iframe from 'react-iframe'
import { Link } from 'react-router-dom';

const  Contact = () => {
  return (
  <section id='contact'>
    <h1 className='my-4 text-orange text-center'>Contact Us</h1>
    <div className='content font-oto'>
      <h2 className='mb-4 text-green'>Tobe Milktea</h2>
      <p>608 Queen St, Fredericton, NB E3B 1C2 (Downtown)</p>
      <p>Email : tobe@email.com</p>
      <p>Phone : (506) 472-4873</p>
      <p>Facebook : <Link to='https://www.facebook.com/tobemilktea' target='_blank'>https://www.facebook.com/tobemilktea</Link></p>
      <div className='images'>
        <img src={img1} alt='Inside of Tobe Milktea'></img>
        <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2773.516539963631!2d-66.64106492403519!3d45.960941700158855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ca418a153ae1da1%3A0x419c184f1a3a1834!2sTobe%20Milk%20Tea!5e0!3m2!1sen!2sca!4v1727630793472!5m2!1sen!2sca"
          width="100%" height="450"
          id=""
          className=""
          display="block"
          loading="lazy"
          position="relative"
          referrerpolicy="no-referrer-when-downgrade"/>
      </div>
      
    </div>
  </section>
)}

export default Contact;