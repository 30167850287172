import React, { useEffect, useState } from 'react';
import './Home.css';
import heroImg from './../../assets/hero image.png';
import rightFrame from './../../assets/right-frame.png';
import leftFrame from './../../assets/left-frame.png';
import bubbleTea from './../../assets/milktea.png';
import noodle from './../../assets/noodle.png';
import VerCard from '../../components/VerticalCard/VerCard';
import HrzCard from '../../components/HorizontalCard/HrzCard';
import Comments from '../../partial/Comments/Comments';
import { Link } from 'react-router-dom';
import {fetchHomepageProducts } from '../../api/api';

const Home = () => {
  const [products, setProducts] = useState({});
  useEffect(() => {
    const getProducts = async () => {
        const data = await fetchHomepageProducts();
        setProducts(data);
    };
    getProducts();
  },[])
  return (
      <div className="home-container my-container">
      <section id='hero-page'>
      <div className='grid'>
          <div>
            <h1 className='text-large'>Come to Tobe Milk Tea</h1>
            <h1 className='text-orange'>The flavor you can't resist!</h1>
            <Link role='button' to='/menu' className="btn btn-secondary my-4">View Menu</Link>
            {products.special && Array.from(products.special).length>0 && 
              <>   
                <h2 className='text-large text-yellow mt-5'>{products.special_title.description}</h2>
                <ul id='week-special-list'>
                {products.special.map((item,index)=> (
                  <VerCard key={index} img={item.image} name={item.name} price={item.price} />
                ))
                }
                </ul>
              </>
            }
          </div>
          <div className='hero-image'>
            <img src={heroImg} alt='Milk Tea'></img>
          </div> 
      </div>
      </section>

    {products.best_seller && Array.from(products.best_seller).length>0 && 
      <section id='best-seller' aria-labelledby='best-title'>
      <div className='box box-primary'> 
        <h2 id='best-title' className='text-orange'>Best Sellers</h2>
        <ul id='best-seller-list'>
          {products.best_seller.map((item,index)=> (
              <VerCard key={index} img={item.image} name={item.name} price={item.price} />
          ))
          }
        </ul>
      </div>
      </section>
      }

      {products.drink && Array.from(products.drink).length>0 && 
      <section id='drink' aria-labelledby='drink-title'>
        <div className='box box-secondary'> 
          <h2 id='drink-title' className='text-green'>Bubble Tea</h2>
            <div className='grid'>
              <div className='postion-relative'>
                <img src={bubbleTea} alt='Bubble Tea' className='image'/>
              </div>
              <ul className='d-flex gap-2 flex-wrap justify-content-end'>
                {products.drink.map((item,index)=> (
                      <HrzCard key={index} img={item.image} name={item.name} price={item.price}  detail={item.description} />
                  ))
                }   
              </ul>
            </div>
        </div>
      </section>
      }

      {products.food && Array.from(products.food).length>0 && 
      <section id='food' aria-labelledby='food-title'>
        <div className='box box-tertiary'> 
          <h2 id='food-title' className='text-orange'>Food and Snacks</h2>
          <div className='grid'>
            <ul className='d-flex gap-2 flex-wrap justify-content-start'>
              {products.food.map((item,index)=> (
                <HrzCard key={index} img={item.image} name={item.name} price={item.price}  detail={item.description} />
                ))
              }   
            </ul>
            <div className='postion-relative'>
            <img src={noodle} alt='Vietnamese Pho Noodles' className='image'/>
            </div>
          </div>  
        </div>
      </section>
      }

      <section id='promise' aria-label='Our Promise'>
        <div className='content'>
          <img id='left-frame' src={leftFrame} alt="flower frame"></img>
          <img id='right-frame' src={rightFrame} alt="flower frame"></img>
          <p>
            <span className='text-orange font-oto text-large'>It's our promise to bring the best quality.</span><br/><br/>
            <span className='text-xlarge font-parisienne'>We prepare food with love and fresh ingredients everyday</span>
          </p>
        </div>
      </section>
      
      <Comments />
      </div>
  );
}

export default Home;
