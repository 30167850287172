import React from 'react';
import './NotFound.css';


const NotFound = () => {
  return (
  <div>
  NotFound
  
  </div>
)}

export default NotFound;