import React from 'react';
import './Footer.css';
import logo from "./../../assets/logo.png"
import { Link } from 'react-router-dom';
import { contact , quicklinks } from '../../data/data';

function Footer() {
  return (
  <footer>
    <div className='my-container footer-content'>
      <div className='logo' >
        <img src={logo} alt="Tobe Milktea Logo"></img>
      </div>
      <div>
        <h2 className='mb-4'>Quick Links</h2>
        <ul className='quicklinks'>
          {quicklinks.map((link,index) => (
          <li key={index} className='font-oto'><Link to={link.link}>{link.name}</Link></li>
          ))}     
        </ul>
      </div>
      <div>
        <h2 className='mb-4'>Find Us</h2>
        <ul className='font-oto'>
          <li>{contact.address}</li>
          <li>{contact.email}</li>
          <li>{contact.phone}</li>
        </ul>
      </div>
   </div>
    
  </footer>
)}

export default Footer;