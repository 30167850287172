import React, { useEffect, useState } from 'react';
import './Promotion.css';
import Card from 'react-bootstrap/Card';
import Product from '../../components/Product/Product';
import { fetchCampaigns ,fetchPromotionProducts,fetchCategories } from '../../api/api';


const  Promotion = () =>{
  const [campaigns, setCampaigns] = useState([]);
  const [products, setProducts] = useState({});
  const [category_list, setCategoryList] = useState([]);

  useEffect(() => {
    const getCampaigns = async () => {
        const data = await fetchCampaigns();
        setCampaigns(data);
    };
    const getPromotionProducts = async () => {
        const data = await fetchPromotionProducts();
        setProducts(data);
    };

    const getCategory = async () => {
        const data = await fetchCategories();
        setCategoryList(data);
    };
    getCampaigns();
    getPromotionProducts();
    getCategory();

  },[])

  return (
  <div className='my-container promotion-container'>
    <section id='campaign'>
    <h2 className='mb-4 text-orange text-center'>Best Deals</h2>
    <ul id='best-deals'>
     {Array.from(campaigns).length> 0 && campaigns.map((item,index)=> (
        <li key={index} className='mb-3'>
        <Card>
        <Card.Body>
          <Card.Title>{item.title}</Card.Title>
          <Card.Text>
            {item.description}
          </Card.Text>
          {item.coupon && <Card.Text>Coupon: <span className='coupon'>{item.coupon}</span></Card.Text>}
          {item.to && 
           <Card.Text className='text-italic'>
            Valid until {item.to}
          </Card.Text>}
        </Card.Body>
        </Card>
        </li>
      ))}
    </ul>
    </section>
    {Object.keys(products).length > 0 && (
    <section id='on-sale'>
      <h1 className='mb-4 text-orange'>Products On Sales</h1>
        {category_list.map((item, index) => (
          <>
            {products[item.code] && Array.from(products[item.code]).length > 0 &&
              <section className='mb-5' id={`s-${item.code}`} key={index} aria-labelledby={`label-${item.name}`}>
                  <h2 id={`label-${item.code}`} className='text-orange mb-4'>{item.name}</h2>
                  <ul className='item-list'>
                    {Array.from(products[item.code]).map((product, index) => (
                      <Product product={product} key={index}></Product>
                    ))}
                  </ul>
              </section>
            }
          </>
              
            ))}
     </section>
    )}
  </div>
)}

export default Promotion;