import React from 'react';
import PropTypes from 'prop-types';
import './Button.css'; 

const Button = ({ variant, onClick, children, ...rest }) => {
  const className = `btn btn-${variant}`;

  return (
    <button className={className} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  variant: 'primary',
  onClick: () => {},
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default Button;
