const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchAllProducts = async () => {
    const response = await fetch(`${BASE_URL}/api/all_products`);
    const data = await response.json();
    return data;
};

export const fetchHomepageProducts = async () => {
    const response = await fetch(`${BASE_URL}/api/homepage_products`);
    const data = await response.json();
    return data;
};

export const fetchProductsByCategory = async () => {
    const response = await fetch(`${BASE_URL}/api/all_products_by_category`);
    const data = await response.json();
    return data;
};

export const fetchCampaigns = async () => {
    const response = await fetch(`${BASE_URL}/api/campaigns`);
    const data = await response.json();
    return data;
};

export const fetchCategories = async () => {
    const response = await fetch(`${BASE_URL}/api/all_categories`);
    const data = await response.json();
    return data;
};

export const fetchPromotionProducts = async () => {
    const response = await fetch(`${BASE_URL}/api/all_promotion_products`);
    const data = await response.json();
    return data;
};