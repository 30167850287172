export const contact = {
    address : "608 Queen St, Fredericton, NB E3B 1C2",
    email : "tobe@email.com",
    phone : "(506) 472-4873"
}

export const quicklinks = [
    {
        name : "Home",
        link : "/"
    },
    {
        name : "Menu",
        link : "/menu"
    },
     {
        name : "Promotion",
        link : "/promotion"
    },
     {
        name : "Contact Us",
        link : "/contact"
    }
]

export const categories = [
    {
        name : "drink",
        title : "Bubble Tea and Drinks",
        desciption : "Fresh tea with toppings"
    } ,
    {
        name : "sandwich",
        title : "Sandwich",
        desciption : "Vietnamese authentic Banhmi"
    }, 
    {
        name : "food",
        title : "Main Course",
        desciption: ""
    },
    { 
        name : "topping",
        title : "Bubble Tea Topping",
        desciption: ""
    },
    {
        name : "dessert",
        title : "Dessert",
        desciption: "" 
    } 
]

export const product_list= [
    {
        sku: "DD-001",
        name: "Tobe milktea classic",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['gluten-free', 'vegan'],
        price : 10,
        discount_percentage : 20,
        promotion : "This week only, New product",
        discontinued : false,
        display : true,
    }
]

export const product_by_category = {
    "drink" : [
        {
        sku: "DD-001",
        name: "Tobe milktea classic",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['gluten-free', 'vegan'],
        price : 10,
        discount_percentage : 20,
        promotion : "This week only, New product",
        discontinued : false,
        display : true,
    },
    {
        sku: "DD-002",
        name: "Tobe milktea classic 2",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['gluten-free'],
        price : 10,
        discount_percentage : 0,
        promotion : "",
        discontinued : false,
        display : true,
    }
    ],
    "sandwich" : [
        {
        sku: "SS-001",
        name: "Banh mi 1",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: [],
        price : 10,
        discount_percentage : 0,
        promotion : "",
        discontinued : false,
        display : true,
    }, {
        sku: "SS-002",
        name: "Banh mi 2",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['Very Good'],
        price : 20,
        discount_percentage : 10,
        promotion : "",
        discontinued : false,
        display : true,
    }
    ],
    "dessert" : [
          {
        sku: "SS-001",
        name: "Dessert 1",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: [],
        price : 10,
        discount_percentage : 0,
        promotion : "",
        discontinued : false,
        display : true,
    }, {
        sku: "SS-002",
        name: "Dessert 2",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['Very Good'],
        price : 20,
        discount_percentage : 10,
        promotion : "",
        discontinued : false,
        display : true,
    }
    ],
    "topping" :[
          {
        sku: "SS-001",
        name: "Topping 1",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: [],
        price : 10,
        discount_percentage : 0,
        promotion : "",
        discontinued : false,
        display : true,
    }, {
        sku: "SS-002",
        name: "Topping 2",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['Very Good'],
        price : 20,
        discount_percentage : 10,
        promotion : "",
        discontinued : false,
        display : true,
    }
    ],
    "food": [
  {
        sku: "SS-001",
        name: "Food 1",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: [],
        price : 10,
        discount_percentage : 0,
        promotion : "",
        discontinued : false,
        display : true,
    }, {
        sku: "SS-002",
        name: "Food 2",
        image : "/milktea.png",
        categories : 'drink',
        description: "Green tea, sugar ,... ",
        tags: ['Very Good'],
        price : 20,
        discount_percentage : 10,
        promotion : "",
        discontinued : false,
        display : true,
    }
    ]
}

export const promotion_campaign = [
    {
        "id" : "001",
        "title" : "Campaign 1",
        "from" : "2024-09-05",
        "to" : "2024-09-10",
        "description" : "Buy 2 get one free.",
        "coupon" : "ASDW12" 
    },
    {
        "id" : "002",
        "title" : "Campaign 2",
        "from" : "2024-09-05",
        "to" : "2024-09-10",
        "description" : "Buy 2 get 10 free.",
        "coupon" : "ASDW12" 
    }
]