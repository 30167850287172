import Card from 'react-bootstrap/Card';
import "./HrzCard.css"
function HrzCard({img,name,price,detail}) {
  const BASE_URL = process.env.REACT_APP_API_URL;

  return (
    <li className='hrz-card'>
    <Card style={{ boxShadow: '5px 5px 10px rgb(0,0,0,0.1)', height: "100%"}}>
      <div className='d-flex gap-2'>
        <Card.Img variant="top" class='product-img' src={`${BASE_URL}/image/product/${img}`} style={{padding : '0.2rem ',width: '80px', flexShrink: "0"}}/>
        <Card.Body>
          <Card.Title>
            <div className='d-flex justify-content-between'>
              <div>{name}</div>
              <div>${price}</div>
            </div>
          </Card.Title>
          <Card.Text style={{fontSize: "0.8rem",fontStyle:"italic"}}>
            {detail}
          </Card.Text>
        </Card.Body>
      </div>
    </Card>
      </li>
  );
}

export default HrzCard;